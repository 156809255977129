import { useState } from "react";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import SettingsIcon from "@material-ui/icons/Settings";
import Link from "@src/components/Link";
import AppBar from "@src/components/AppBar";
import AppBarOffset from "@src/components/AppBarOffset";

import Joyride from "react-joyride";
import { Step } from "react-joyride/types";

import PermissionProvider from "@src/contexts/permissions/providers/PermissionProvider";
import { useMounted } from "@src/utils";
import { useSelector } from "react-redux";
import { agentCompanySelectors, RootState, selectMyUser } from "@src/redux";
import { CompanyTypeEnum, RoleEnum } from "@src/openapi-generator";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
} from "@material-ui/core";
import ButtonWithConfirmDialog from "@src/components/ButtonWithConfirmDialog";
import { useRouter } from "next/router";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      background: "linear-gradient(to left, #73B7FF, #86DDF3 50%, #D5FFEC)",
      height: "100vh",
    },
    backgroundLogo: {
      top: 100,
      left: 20,
      position: "fixed",
      height: "calc(100% - 105px)",
      opacity: 0.4,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      zIndex: 1,
    },
    joyrideZIndex: {
      zIndex: theme.zIndex.drawer + 100,
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      padding: "20px clamp(0px, 7vw, 129px) 5px clamp(0px, 7vw, 129px)",
      borderRadius: 20,
    },
    cardMedia: {
      paddingTop: "56.25%", // 16:9
    },
    cardContent: {
      flexGrow: 1,
      display: "flex",
      alignItems: "center",
    },
    box: {
      marginTop: 50,
      [theme.breakpoints.up(1620)]: {
        marginRight: "-8%",
        paddingLeft: "8%",
      },
    },
  })
);

export default function Index() {
  const classes = useStyles();
  const theme = useTheme();
  const me = useSelector(selectMyUser);
  const [joyrideState, setJoyrideState] = useState<{
    steps: Step[];
    run: boolean;
  }>({
    run: false,
    steps: [
      {
        content: "オリエンテーションはここで閲覧・編集します。",
        target: "#orientation_list",
        disableBeacon: true,
      },
      {
        content: "プレゼンテーションはここで閲覧・編集します。",
        target: "#presentation_list",
        disableBeacon: true,
      },
      {
        content: "代理店の権限設定はこちらで行います。",
        target: "#permission",
        disableBeacon: true,
      },
    ],
  });
  const router = useRouter();

  const agentCompanies = useSelector((state: RootState) => {
    return agentCompanySelectors.selectAll(state);
  });

  // react-joyrideがSSRに対応していないため、Clientでrenderするようにする
  // https://github.com/vercel/next.js/blob/canary/examples/progressive-render/pages/index.js
  const isMounted = useMounted();

  return (
    <PermissionProvider>
      <img
        src="/images/logo_home.png"
        alt="logo"
        className={classes.backgroundLogo}
      />
      <div className={classes.root}>
        {isMounted ? (
          <Joyride
            steps={joyrideState.steps}
            callback={(data) => {
              if (data.action === "close") {
                setJoyrideState({ ...joyrideState, run: false });
              }
            }}
            continuous={true}
            showProgress={true}
            showSkipButton={true}
            run={joyrideState.run}
            styles={{
              options: { zIndex: theme.zIndex.drawer + 100 },
            }}
            disableOverlayClose={true}
            locale={{
              back: "前へ",
              close: "閉じる",
              last: "終了",
              next: "次へ",
              skip: "スキップ",
            }}
          />
        ) : null}
        <AppBar showDrawer={false} />
        <main className={classes.content}>
          <AppBarOffset />
          <Container maxWidth="lg">
            <Box className={classes.box}>
              {me &&
              (me.role === RoleEnum.SystemMaster ||
                me.role === RoleEnum.SystemAdmin) ? (
                <Link href="/admin/system_company">adlog 従業員管理</Link>
              ) : null}
              <br />

              {me && me.company_type === CompanyTypeEnum.System ? (
                <Link href="/admin/clients">依頼主企業一覧</Link>
              ) : null}
              {me && me.company_type === CompanyTypeEnum.Client ? (
                <>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={6}>
                      <Link href="/campaign">
                        <Card
                          className={classes.card}
                          style={{
                            backgroundColor: "#ff9933",
                            border: "2px solid #da5800",
                          }}
                        >
                          <CardContent className={classes.cardContent}>
                            <Typography
                              gutterBottom
                              variant="h4"
                              component="h2"
                              style={{
                                color: "#ffffff",
                                fontSize: "clamp(1.55rem, 2.1vw, 2.3rem);",
                                fontWeight: 600,
                              }}
                              align="center"
                            >
                              既存のキャンペーン一覧
                            </Typography>
                          </CardContent>
                        </Card>
                      </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Link href="/campaign/create">
                        <Card
                          className={classes.card}
                          style={{
                            backgroundColor: "#0077ff",
                            border: "2px solid #000099",
                          }}
                        >
                          <CardContent className={classes.cardContent}>
                            <Typography
                              gutterBottom
                              variant="h4"
                              component="h2"
                              style={{
                                color: "#ffffff",
                                fontSize: "clamp(1.55rem, 2.1vw, 2.3rem);",
                                fontWeight: 600,
                              }}
                              align="center"
                            >
                              新規キャンペーンの作成
                            </Typography>
                          </CardContent>
                        </Card>
                      </Link>
                    </Grid>

                    {me &&
                    (me.role === RoleEnum.ClientOwner ||
                      me.role === RoleEnum.ClientAdmin) ? (
                      <Grid
                        item
                        xs={12}
                        style={{ paddingLeft: "clamp(20px, 5vw, 120px)" }}
                      >
                        <Link href="/client">
                          <div style={{ color: "#777777" }}>
                            <Typography
                              gutterBottom
                              variant="h4"
                              component="h2"
                              style={{
                                fontSize: "clamp(1.4825rem,2vw,1.9rem)",
                                fontWeight: 600,
                                display: "flex",
                                alignItems: "center",
                                letterSpacing: 0,
                              }}
                            >
                              <SettingsIcon
                                style={{
                                  fontSize: "clamp(1.8rem,2.5vw,2.4rem)",
                                }}
                              />
                              アカウントの管理
                            </Typography>
                            <h3
                              style={{
                                letterSpacing: 0,
                                fontSize: "clamp(1.15rem,1.2vw,1.3rem)",
                                fontWeight: 600,
                                margin: 0,
                                marginLeft: "clamp(1.8rem,2.5vw,2.4rem)",
                              }}
                            >
                              自社ユーザーの登録・削除 / 自社情報の変更 /
                              請求先情報の変更 /
                            </h3>
                            <h3
                              style={{
                                letterSpacing: 0,
                                fontSize: "clamp(1.15rem,1.2vw,1.3rem)",
                                fontWeight: 600,
                                margin: 0,
                                marginLeft: "clamp(1.8rem,2.5vw,2.4rem)",
                              }}
                            >
                              広告代理店の登録・削除
                            </h3>
                          </div>
                        </Link>
                      </Grid>
                    ) : null}
                  </Grid>
                </>
              ) : null}

              {me &&
              me.company_type === CompanyTypeEnum.Agent &&
              agentCompanies ? (
                <Grid container spacing={4}>
                  {agentCompanies.map((agentCompany) => (
                    <Grid item key={agentCompany.id} xs={12} sm={6} md={4}>
                      <Card className={classes.card} style={{ padding: 0 }}>
                        <CardContent className={classes.cardContent}>
                          <Typography gutterBottom variant="h5" component="h2">
                            {agentCompany.client?.name}
                          </Typography>
                        </CardContent>
                        <CardActions
                          style={{
                            justifyContent: "space-around",
                            padding: "8px 24px",
                          }}
                        >
                          <Button size="small" color="primary">
                            <Link
                              href={`/agent/clients/${agentCompany.id}/campaign`}
                            >
                              キャンペーン一覧
                            </Link>
                          </Button>
                          {agentCompany.payer === CompanyTypeEnum.Agent ? (
                            <ButtonWithConfirmDialog
                              buttonContent="利用料支払設定"
                              buttonProps={{
                                size: "small",
                                style: {
                                  padding: "0 0 0 5px",
                                  color: "#556CD6",
                                  background: "none",
                                  boxShadow: "none",
                                },
                              }}
                              dialogTitle={agentCompany.client?.name}
                              dialogContentText={
                                <p style={{ margin: "40px 15px" }}>
                                  <span style={{ marginRight: 30 }}>
                                    代理店利用料支払
                                  </span>
                                  広告代理店
                                </p>
                              }
                              cancelBtn={() => <span></span>}
                              okBtn={() => (
                                <Button
                                  onClick={() => {
                                    router.push(
                                      `/agent/clients/${agentCompany.id}/billing`
                                    );
                                  }}
                                  color="primary"
                                  variant="contained"
                                  autoFocus
                                  style={{
                                    marginRight: "40%",
                                    marginBottom: 20,
                                  }}
                                >
                                  変更
                                </Button>
                              )}
                            />
                          ) : (
                            <ButtonWithConfirmDialog
                              buttonContent="利用料支払設定"
                              buttonProps={{
                                size: "small",
                                style: {
                                  padding: "0 0 0 5px",
                                  color: "#556CD6",
                                  background: "none",
                                  boxShadow: "none",
                                },
                              }}
                              dialogTitle={agentCompany.client?.name}
                              dialogContentText={
                                <p style={{ margin: "40px 15px" }}>
                                  <span style={{ marginRight: 30 }}>
                                    代理店利用料支払
                                  </span>
                                  クライアント
                                </p>
                              }
                              cancelBtn={() => <span></span>}
                              okBtn={() => <span></span>}
                            />
                          )}
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              ) : null}
            </Box>
          </Container>
        </main>
      </div>
    </PermissionProvider>
  );
}
