import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import {
  ReactNode,
  Fragment,
  useState,
  MouseEvent as ReactMouseEvent,
} from "react";

type Props = {
  buttonProps?: ButtonProps;
  buttonContent: ReactNode;
  dialogTitle: ReactNode;
  dialogContentText: ReactNode;
  onClick?: (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void;
  cancelBtn?: (closeCb: any) => ReactNode;
  okBtn?: (closeCb: any) => ReactNode;
  icon?: ReactNode;
};

export default function ButtonWithConfirmDialog(props: Props) {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isDeleteBtn = props.buttonContent === "削除";
  const deleteBtnStyle = {
    ...(props.buttonProps?.style || {}),
    background: "#d9d9d9",
    color: "#333",
  };

  return (
    <Fragment>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
        {...props.buttonProps}
        style={isDeleteBtn ? deleteBtnStyle : props.buttonProps?.style || {}}
      >
        {props.icon}
        {isDeleteBtn && !props.icon && (
          <Delete style={{ fontSize: "1.2rem", marginRight: 3 }} />
        )}
        {props.buttonContent}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.dialogContentText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {props.cancelBtn ? (
            props.cancelBtn(handleClose)
          ) : (
            <Button onClick={handleClose} color="primary">
              いいえ
            </Button>
          )}
          {props.okBtn ? (
            props.okBtn(handleClose)
          ) : (
            <Button
              onClick={(event) => {
                handleClose();
                if (props.onClick) {
                  props.onClick(event);
                }
              }}
              color="primary"
              autoFocus
            >
              はい
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
